import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout.js';
import { TinyLetter } from 'react-tinyletter';

export default () => (
  <div id='newsletter'>
    <Layout>
        <header>
          <h2>Good Information</h2>
          <p>By Ryland Duncan</p>
        </header>
        <p>
          I will email you good information. If you want to know about something let me know and I will try to already know some information about it. My name is Ryland. (there is a confirmation email that sends when you subscribe and it might go somewhere kooky) 
        </p>
      <a className="archive" href="https://tinyletter.com/rylandduncan/archive" target="_blank" rel="noreferrer noopen">View Letter Archive</a>
        <TinyLetter list="rylandduncan" className='subscribe-form'>
          <input type="email"/>
          <input type="submit" value="Subscribe!"/>
        </TinyLetter>
      <Link className="privacy" to="/privacy-policy">Ryland's Privacy Policy</Link>
    </Layout>
  </div>
)
